import httpUtil from "@/utils/httpUtil";

/** 添加轮播 */
export function addRotation(params,type) {
    return httpUtil.post("/api/assPc/content/addRotation", params, type)
}
/** 修改轮播 */
export function upRotation(params,type) {
    return httpUtil.post("/api/assPc/content/upRotation", params, type)
}
/** 轮播查询 */
export function selectRotationList(params,type) {
    return httpUtil.post("/api/assPc/content/selectRotationPageList", params, type)
}
/** 删除查询 */
export function delRotation(params,type) {
    return httpUtil.post("/api/assPc/content/delRotation", params, type)
}
/** 添加公告 */
export function addOrgNotice(params,type) {
    return httpUtil.post("/api/assPc/content/addOrgNotice", params, type)
}
/** 修改公告 */
export function upOrgNotice(params,type) {
    return httpUtil.post("/api/assPc/content/upOrgNotice", params, type)
}
/** 删除公告 */
export function delOrgNotice(params,type) {
    return httpUtil.post("/api/assPc/content/delOrgNotice", params, type)
}
/** 获取公告 */
export function selectOrgNoticeList(params,type) {
    return httpUtil.post("/api/assPc/content/selectOrgNoticePageList", params, type)
}

/** 添加组织工作 */
export function addWork(params,type) {
    return httpUtil.post("/api/assPc/content/addWork", params, type)
}
/** 修改组织工作 */
export function upWork(params,type) {
    return httpUtil.post("/api/assPc/content/upWork", params, type)
}
/** 删除组织工作 */
export function delWork(params,type) {
    return httpUtil.post("/api/assPc/content/delWork", params, type)
}
/** 获取组织工作 */
export function selectWorkList(params,type) {
    return httpUtil.post("/api/assPc/content/selectWorkPageList", params, type)
}

/** 添加文章类型 */
export function addArticleType(params,type) {
    return httpUtil.post("/api/assPc/content/addArticleType", params, type)
}
/** 修改文章类型 */
export function upArticleType(params,type) {
    return httpUtil.post("/api/assPc/content/upArticleType", params, type)
}
/** 删除文章类型 */
export function delArticleType(params,type) {
    return httpUtil.post("/api/assPc/content/delArticleType", params, type)
}
/** 获取文章类型 */
export function selectArticleTypePageList(params,type) {
    return httpUtil.post("/api/assPc/content/selectArticleTypePageList", params, type)
}


/** 添加捐赠管理 */
export function addDonation(params,type) {
    return httpUtil.post("/api/assPc/content/addDonation", params, type)
}
/** 修改捐赠管理 */
export function upDonation(params,type) {
    return httpUtil.post("/api/assPc/content/upDonation", params, type)
}
/** 删除捐赠管理 */
export function delDonation(params,type) {
    return httpUtil.post("/api/assPc/content/delDonation", params, type)
}
/** 获取捐赠管理 */
export function selectDonationList(params,type) {
    return httpUtil.post("/api/assPc/content/selectDonationPageList", params, type)
}

/** 添加日常捐赠*/
export function addDonationDaily(params,type) {
    return httpUtil.post("/api/assPc/content/addDonationDaily", params, type)
}
/** 修改日常捐赠 */
export function upDonationDaily(params,type) {
    return httpUtil.post("/api/assPc/content/upDonationDaily", params, type)
}
/** 删除日常捐赠 */
export function delDonationDaily(params,type) {
    return httpUtil.post("/api/assPc/content/delDonationDaily", params, type)
}
/** 获取日常捐赠 */
export function selectDonationDailyList(params,type) {
    return httpUtil.post("/api/assPc/content/selectDonationDailyPageList", params, type)
}

/** 获取文章类型 */
export function selectArticleTypeList(params,type) {
    return httpUtil.post("/api/assPc/content/selectArticleTypeList", params, type)
}
/**
 * 捐赠记录 */
export function selectDonationDetailsPageList(params,type) {
    return httpUtil.post("/api/assPc/content/selectDonationDetailsPageList", params, type)
}

/**
 * 文章置顶 */
export function upWorkTop(params,type) {
    return httpUtil.post("/api/assPc/content/upWorkTop", params, type)
}

/* 查询文章子分类 */
export function queryChildType(params,type) {
    return httpUtil.post("/api/assPc/content/queryChildType", params, type)
}

/* 添加主文章分类 */
export function belongArticleType(params,type) {
    return httpUtil.post("/api/assPc/content/belongArticleType", params, type)
}

/* 自定义页面添加 */
export function addCustomPage(params,type) {
    return httpUtil.post("/api/assPc/CustomPage/addCustomPage", params, type)
}
/* 自定义页面列表 */
export function queryCustomPage(params,type) {
    return httpUtil.post("/api/assPc/CustomPage/queryCustomPage", params, type)
}
/* 删除自定义页面 */
export function delCustomPageById(params,type) {
    return httpUtil.post("/api/assPc/CustomPage/delCustomPageById", params, type)
}
/* 自定义页面详情 */
export function queryCustomPageById(params,type) {
    return httpUtil.post("/api/assPc/CustomPage/queryCustomPageById", params, type)
}
/* 修改单文章 */
export function updatePageWork(params,type) {
    return httpUtil.post("/api/assPc/CustomPage/updatePageWork", params, type)
}
/* 修改单文章 */
export function delPageWork(params,type) {
    return httpUtil.post("/api/assPc/CustomPage/delPageWork", params, type)
}
/* 修改页面 */
export function updateCustomPageById(params,type) {
    return httpUtil.post("/api/assPc/CustomPage/updateCustomPageById", params, type)
}
/* 页面设置 */
export function addPageImg(params,type) {
    return httpUtil.post("/api/assPc/CustomPage/addPageImg", params, type)
}

//首页菜单----自定义页面
export function queryCustomPageAll(params,type) {
    return httpUtil.post("/api/assPc/CustomPage/queryCustomPageAll", params, type)
}
//预约商品
export function selectProductsPage(params,type) {
    return httpUtil.post("/api/crmPc/products/selectProductsPage", params, type)
}

export function queryPgaeSeting(params,type) {
    return httpUtil.post("/api/assPc/CustomPage/queryPgaeSeting", params, type)
}
//删除图片
export function delPgaeImg(params,type) {
    return httpUtil.post("/api/assPc/CustomPage/delPgaeImg", params, type)
}
//删除类型
export function delPageTypeById(params,type) {
    return httpUtil.post("/api/assPc/CustomPage/delPageTypeById", params, type)
}
//修改类型
export function updatePgaeSeting(params,type) {
    return httpUtil.post("/api/assPc/CustomPage/updatePgaeSeting", params, type)
}
//修改类型
export function addPageType(params,type) {
    return httpUtil.post("/api/assPc/CustomPage/addPageType", params, type)
}
//修改类型
export function addPageImgOne(params,type) {
    return httpUtil.post("/api/assPc/CustomPage/addPageImgOne", params, type)
}


//查询文章大分类
export function queryArticleTypeUse(params,type) {
    return httpUtil.post("/api/assPc/content/queryArticleTypeUse", params, type)
}

// 参数 classType   根据类型查询文章 --校验类型下是否有文章
export function selectWorkDesc(params,type) {
    return httpUtil.post("/api/assPc/content/selectWorkDesc", params, type)
}






