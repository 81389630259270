<template>
	<div>
		<el-table @row-click="clickRow" v-loading="loading" :ref="multipleTable" :data="tableData" :span-method="spanMethod"
			:row-key="rowKey" border tooltip-effect="dark" :style="{ marginTop: marginTop }"
			@selection-change="SelectionChange">
			<slot name="table"></slot>
		</el-table>

		<div style="display: flex; justify-content: flex-end; margin-top: 16px" v-if="tableData.length !== 0">
			<!-- <div style="display: flex; justify-content: flex-end; margin-top: 40px" v-if='tableData.length !== 0 && hideTable == false'> -->

			<el-pagination v-if="paginationVisible" background @size-change="sizeChange" @current-change="currentChange"
				:current-page="currentPage" :page-sizes="[10, 30, 50, 100, 200]"
				layout="total, sizes, prev, pager, next, jumper" class="" :total="total" :pager-count="5" />
		</div>
	</div>
</template>

<script>
export default {
	name: "commonTable",
	props: {
		multipleTable: {
			type: String,
			default: 'multipleTable'
		},
		tableData: {
			type: Array,
			default: () => [],
		},
		hideTable: { //显隐表单
			type: Boolean,
			default: false
		},
		spanMethod: {
			type: Function,
			default: () => { },
		},

		total: {
			//总条数
			type: Number,
			default: 0,
		},

		currentPage: {
			//当前页数
			type: Number,
			default: 1,
		},

		loading: {
			//表格加载
			type: Boolean,
			default: false,
		},

		handleSizeChange: {
			//监听切换条数
			type: Function,
			default: () => { },
		},

		handleCurrentChange: {
			type: Function,
			default: () => { },
		},

		handleSelectionChange: {
			type: Function,
			default: () => { },
		},
		handleClickRow: {
			type: Function,
			default: () => { },
		},
		paginationVisible: {
			//表格加载
			type: Boolean,
			default: true,
		},
		clearName: {
			type: String,
			default: '',
		},
		itemKey: {
			type: String,
			default: '',
		},
		marginTop: {
			type: String,
			default: '24px',
		},
		rowKey: Function || String,
	},
	data() {
		return {
			computedClearName: [
				'demandProtuctsCheck',
			]
		};
	},
	methods: {
		/**@method 监听切换条数并调用父组件方法 */
		sizeChange(val) {
			this.$emit("handleSizeChange", val);
			/*  handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			} */
		},
		/**@method 监听下一页并调用父组件方法 */
		currentChange(val) {
			this.$emit("handleCurrentChange", val);
			/* handleCurrentChange(val) {
			  console.log(`当前页: ${val}`);
			} */
		},
		SelectionChange(val) {
			if (this.computedClearName.includes(this.clearName)) {
				const item = val.find(item => { return item[this.itemKey] != "0" });
				if (item) {
					this.$refs.multipleTable.clearSelection();
					this.$message({
						message: "请选择审核中的需求",
						type: "warning",
					});
					return;
				}
			}
			this.$emit("handleSelectionChange", val);
		},
		clickRow(row) {
			this.$emit("handleClickRow", row);
		},
		setValue(obj) {
			this.$refs.multipleTable.toggleRowSelection(obj, true);
		},
		clearValue() {
			this.$refs.multipleTable.clearSelection();
		},
	},
};
</script>

<style lang="scss" scoped></style>
